/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pointer } from '../main/pointer';
export interface IAnimation {
  sizeFrame: Pointer;
  leftShift: Pointer;
  rightShift: Pointer;
  speed: number;
  countFrame: number;
  scale: number;
}

export const mediaData: Record<string, any> = {
  background: {
    size: new Pointer(1600, 844),
    center: new Pointer(980, 400),
  },
  platforms: {
    down: {
      size: new Pointer(1145, 60),
      shift: new Pointer(0, -15),
    },
    firstSmall: {
      size: new Pointer(154, 45),
      shift: new Pointer(0, -15),
    },
    secondSmall: {
      size: new Pointer(154, 45),
      shift: new Pointer(0, -15),
    },
    middle: {
      size: new Pointer(320, 45),
      shift: new Pointer(0, -10),
    },
    t: {
      size: new Pointer(326, 170),
      shift: new Pointer(-3, -125),
    },
  },
  fire: {
    idleAnimation: {
      sizeFrame: new Pointer(62, 43),
      leftShift: new Pointer(-44, -44),
      rightShift: new Pointer(-44, -44),
      speed: 7,
      countFrame: 4,
      scale: 2,
    },
    moveAnimation: {
      sizeFrame: new Pointer(62, 43),
      leftShift: new Pointer(-44, -44),
      rightShift: new Pointer(-44, -44),
      speed: 7,
      countFrame: 4,
      scale: 2,
    },
    attackAnimation: {
      sizeFrame: new Pointer(62, 43),
      leftShift: new Pointer(-44, -44),
      rightShift: new Pointer(-44, -44),
      speed: 17,
      countFrame: 10,
      scale: 2,
    },
    hurtAnimation: {
      sizeFrame: new Pointer(62, 43),
      leftShift: new Pointer(-44, -44),
      rightShift: new Pointer(-44, -44),
      speed: 17,
      countFrame: 3,
      scale: 2,
    },
    dieAnimation: {
      sizeFrame: new Pointer(62, 43),
      leftShift: new Pointer(-44, -44),
      rightShift: new Pointer(-44, -44),
      speed: 17,
      countFrame: 8,
      scale: 2,
    },
    projectile: {
      sizeFrame: new Pointer(32, 16),
      leftShift: new Pointer(-4, -6),
      rightShift: new Pointer(-39, -6),
      speed: 8,
      countFrame: 4,
      scale: 2,
    },
    projectileCollide: {
      sizeFrame: new Pointer(32, 26),
      leftShift: new Pointer(-4, -6 - 5 - 1),
      rightShift: new Pointer(-39, -6 - 5 - 1),
      speed: 8,
      countFrame: 6,
      scale: 2,
    },
  },
  water: {
    idleAnimation: {
      sizeFrame: new Pointer(41, 32),
      leftShift: new Pointer(-33, -21),
      rightShift: new Pointer(-14, -21),
      speed: 7,
      countFrame: 4,
      scale: 2,
    },
    moveAnimation: {
      sizeFrame: new Pointer(41, 32),
      leftShift: new Pointer(-36, -21),
      rightShift: new Pointer(-14, -21),
      speed: 7,
      countFrame: 4,
      scale: 2,
    },
    attackAnimation: {
      sizeFrame: new Pointer(41, 32),
      leftShift: new Pointer(-33, -21),
      rightShift: new Pointer(-14, -21),
      speed: 17,
      countFrame: 10,
      scale: 2,
    },
    hurtAnimation: {
      sizeFrame: new Pointer(41, 32),
      leftShift: new Pointer(-33, -21),
      rightShift: new Pointer(-14, -21),
      speed: 17,
      countFrame: 3,
      scale: 2,
    },
    dieAnimation: {
      sizeFrame: new Pointer(41, 32),
      leftShift: new Pointer(-33, -21),
      rightShift: new Pointer(-14, -21),
      speed: 17,
      countFrame: 8,
      scale: 2,
    },
    projectile: {
      sizeFrame: new Pointer(32, 16),
      leftShift: new Pointer(-8, -8),
      rightShift: new Pointer(-35, -8),
      speed: 8,
      countFrame: 4,
      scale: 2,
    },
    projectileCollide: {
      sizeFrame: new Pointer(32, 26),
      leftShift: new Pointer(-12, -13 - 5),
      rightShift: new Pointer(-33, -13 - 5),
      speed: 8,
      countFrame: 6,
      scale: 2,
    },
  },
  wind: {
    idleAnimation: {
      sizeFrame: new Pointer(45, 45),
      leftShift: new Pointer(-31, -44),
      rightShift: new Pointer(-24, -44),
      speed: 7,
      countFrame: 4,
      scale: 2,
    },
    moveAnimation: {
      sizeFrame: new Pointer(45, 45),
      leftShift: new Pointer(-28, -44),
      rightShift: new Pointer(-30, -44),
      speed: 7,
      countFrame: 4,
      scale: 2,
    },
    attackAnimation: {
      sizeFrame: new Pointer(45, 45),
      leftShift: new Pointer(-31, -44),
      rightShift: new Pointer(-24, -44),
      speed: 17,
      countFrame: 10,
      scale: 2,
    },
    hurtAnimation: {
      sizeFrame: new Pointer(45, 45),
      leftShift: new Pointer(-31, -44),
      rightShift: new Pointer(-24, -44),
      speed: 17,
      countFrame: 3,
      scale: 2,
    },
    dieAnimation: {
      sizeFrame: new Pointer(45, 45),
      leftShift: new Pointer(-31, -44),
      rightShift: new Pointer(-24, -44),
      speed: 17,
      countFrame: 8,
      scale: 2,
    },
    projectile: {
      sizeFrame: new Pointer(32, 16),
      leftShift: new Pointer(-4, -6),
      rightShift: new Pointer(-39, -6),
      speed: 8,
      countFrame: 4,
      scale: 2,
    },
    projectileCollide: {
      sizeFrame: new Pointer(32, 26),
      leftShift: new Pointer(-4, -6 - 5 - 5),
      rightShift: new Pointer(-39, -6 - 5 - 5),
      speed: 8,
      countFrame: 6,
      scale: 2,
    },
  },
  earth: {
    idleAnimation: {
      sizeFrame: new Pointer(47, 43),
      leftShift: new Pointer(-31, -44),
      rightShift: new Pointer(-31, -44),
      speed: 7,
      countFrame: 4,
      scale: 2,
    },
    moveAnimation: {
      sizeFrame: new Pointer(47, 43),
      leftShift: new Pointer(-31, -44),
      rightShift: new Pointer(-31, -44),
      speed: 7,
      countFrame: 4,
      scale: 2,
    },
    attackAnimation: {
      sizeFrame: new Pointer(47, 43),
      leftShift: new Pointer(-31, -44),
      rightShift: new Pointer(-31, -44),
      speed: 17,
      countFrame: 10,
      scale: 2,
    },
    hurtAnimation: {
      sizeFrame: new Pointer(47, 43),
      leftShift: new Pointer(-31, -44),
      rightShift: new Pointer(-31, -44),
      speed: 17,
      countFrame: 3,
      scale: 2,
    },
    dieAnimation: {
      sizeFrame: new Pointer(47, 43),
      leftShift: new Pointer(-31, -44),
      rightShift: new Pointer(-31, -44),
      speed: 17,
      countFrame: 8,
      scale: 2,
    },
    projectile: {
      sizeFrame: new Pointer(32, 16),
      leftShift: new Pointer(-8, -6),
      rightShift: new Pointer(-35, -6),
      speed: 8,
      countFrame: 4,
      scale: 2,
    },
    projectileCollide: {
      sizeFrame: new Pointer(32, 26),
      leftShift: new Pointer(-8, -6 - 5 - 5),
      rightShift: new Pointer(-35, -6 - 5 - 5),
      speed: 8,
      countFrame: 6,
      scale: 2,
    },
  },
};
